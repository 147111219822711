import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Flex } from '@twilio-paste/flex';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import Changelog from '@twilio-paste/flex/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPasteLayout(filter: {name: {eq: "@twilio-paste/flex"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/layout/flex/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Flex" categoryRoute={SidebarCategoryRoutes.LAYOUT} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/layout/flex" storybookUrl="/?path=/story/layout-flex--flex-alignment-options" data={props.data.allPasteLayout.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <h3>{`About Flex`}</h3>
        <p>{`Flex utilizes a simplified flexbox API that allows you more efficient ways to layout, align, and
distribute space among elements in a container. The Flex API helps take the confusion out of using
flexbox CSS properties, by giving you simple methods to change properties like flex,
flex-direction, flex-wrap, and so on.`}</p>
        <p>{`Flexible elements can adapt to fill space within the layout. This allows Flex to work well for
product layouts or elements that change orientation, resize, stretch, or shrink.`}</p>
        <p>{`The Flex API was created because flexbox can be a difficult CSS property to wrap your head around,
which leads to confusion on how it all works. If you’d like to learn more about flexbox, the
learning resources below provide further information:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox"
            }}>{`MDN Learn Flexbox`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://css-tricks.com/snippets/css/a-guide-to-flexbox/"
            }}>{`CSS Tricks Guide to Flexbox`}</a></li>
        </ul>
        <h4>{`Accessibility`}</h4>
        <p>{`The Flex component is an all purpose component. By default, it has no accessibility concerns. If
you use the Flex as a custom element, it is up to you to manage the resulting accessibility implications.`}</p>
        <h2>{`Examples`}</h2>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex>
    <Flex>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
      >
        Left area
      </Box>
    </Flex>
    <Flex grow>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="100%"
      >
        Right area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h4>{`Flex Properties`}</h4>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex>
    <Flex grow shrink basis>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        width="100%"
      >
        Left area
      </Box>
    </Flex>
    <Flex grow shrink basis>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="100%"
      >
        Right area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h4>{`Vertical Property`}</h4>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex vertical>
    <Flex grow>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        width="100%"
      >
        Left area
      </Box>
    </Flex>
    <Flex grow>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="100%"
      >
        Right area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h4>{`Wrap Property`}</h4>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex wrap>
    <Flex basis={800}>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        width="100%"
      >
        Left area
      </Box>
    </Flex>
    <Flex>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="100%"
      >
        Right area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h4>{`Vertical Alignment Properties`}</h4>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex vAlignContent="center">
    <Flex grow>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        width="100%"
      >
        Left area
      </Box>
    </Flex>
    <Flex grow>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="100%"
        height="size10"
      >
        Right area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h4>{`Horizontal Alignment Properties`}</h4>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex hAlignContent="center" vertical>
    <Flex>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        width="100%"
      >
        Left area
      </Box>
    </Flex>
    <Flex>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="size30"
      >
        Right area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h3>{`Composition Notes`}</h3>
        <p>{`The Flex component is used to compose flexible layout experiences. Let's look at an example
where we need to build a flexible content layout with two sidebars: its has a left sidebar,
a content area, and a right sidebar. Here's how we’ll compose the flexible layout using Flex:`}</p>
        <LivePreview scope={{
          Box,
          Flex
        }} language="jsx" mdxType="LivePreview">
  {`<Flex>
    <Flex>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        height="size20"
      >
        Left sidebar area
      </Box>
    </Flex>
    <Flex grow>
      <Box
        backgroundColor="colorBackgroundPrimaryLighter"
        padding="space40"
        width="100%"
        height="size20"
      >
        Content area
      </Box>
    </Flex>
    <Flex>
      <Box
        backgroundColor="colorBackgroundPrimaryLight"
        padding="space40"
        height="size20"
      >
        Right sidebar area
      </Box>
    </Flex>
  </Flex>`}
        </LivePreview>
        <h3>{`When to use Flex`}</h3>
        <p>{`The Flex component should be used when a flexible layout is needed with a page layout or custom component layout.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use Flex to layout your page or custom component." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Use Flex to set margins or padding within layouts and components." preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use Flex to build responsive experiences." preview={false} mdxType="Do" />
  <Dont title="Don't" body="Use inline CSS or classNames to add styles that Flex can provide." preview={false} mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/flex - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Flex} from '@twilio-paste/flex';

const Component = () => <Flex>Foo</Flex>;
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`keyof JSX.IntrinsicElements`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A custom HTML tag`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`div`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`display?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, ResponsiveValue<'flex', 'inline-flex'>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Display property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'flex'`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`basis?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, ResponsiveValue<string, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Flex basis property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`grow?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, number, ResponsiveValue<boolean, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Flex grow property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`shrink?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, ResponsiveValue<string, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Flex shrink property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hAlignContent?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, ResponsiveValue<string, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Horizontal alignment property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`vAlignContent?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, ResponsiveValue<string, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Vertical alignment property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`vertical?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, number, ResponsiveValue<boolean, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Flex direction property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`wrap?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, number, ResponsiveValue<boolean, number>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Flex wrap property`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      